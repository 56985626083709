<script lang="ts" setup>
import type { GameLastWinner } from "@/types";

const SHOW_BADGE_TIMEOUT_DELAY = 6000;

const props = defineProps<{
	winner: GameLastWinner | undefined;
}>();

const { t } = useT();
const showBadge = ref(false);

watch(
	() => props.winner,
	(newData) => {
		if (!newData || showBadge.value) {
			return;
		}

		showBadge.value = true;
		setTimeout(() => {
			showBadge.value = false;
		}, SHOW_BADGE_TIMEOUT_DELAY);
	},
	{ deep: true }
);
</script>
<template>
	<ABadge v-if="showBadge && winner" class="feed-badge" :class="{ animated: showBadge }" variant="info">
		<div class="badge-content">
			<AText :modifiers="['uppercase', 'bold']" variant="ternopil">
				{{ winner.type === "bigwin" ? t("Big Win:") : t("Win:") }}
			</AText>
			<template v-if="winner.type === 'win'">
				<NuxtImg
					class="icon"
					:src="`/nuxt-img/banners/${winner.gameMode === 'SweepStakes' ? 'entries' : 'coins'}.png`"
					:srcset="`/nuxt-img/banners/${winner.gameMode === 'SweepStakes' ? 'entries' : 'coins'}@2x.png 2x`"
					alt="coins"
					format="webp"
					width="16"
					height="16"
				/>
				<AText :modifiers="['bold']" variant="ternopil">
					{{ numberFormat(winner.data.winAmount) }}
				</AText>
			</template>
			<template v-if="winner.type === 'bigwin'">
				<NuxtIcon class="icon" name="16/big-win" filled />
				<AText :modifiers="['bold']" variant="ternopil">
					{{ numberFormat(winner.data.big_win_coefficient) }}
				</AText>
			</template>
		</div>
	</ABadge>
</template>

<style lang="scss" scoped>
.feed-badge {
	&.info {
		line-height: 1;
		border: 1px solid;
		border-color: var(--clara);
		color: var(--clara);
		position: absolute;
		width: max-content;
		left: 0;
		right: 0;
		bottom: 80px;
		margin: 0 auto;
	}

	.badge-content {
		display: flex;
		align-items: center;
	}

	.icon {
		margin-right: gutter(0.5);
		font-size: 16px;
	}

	.player-icon {
		font-size: 12px;
		margin-right: gutter(0.5);
	}

	&.animated {
		animation: translate 6s forwards;
	}
}

@keyframes translate {
	0% {
		transform: translateY(0);
		opacity: 0;
	}

	15% {
		transform: translateY(14px);
		opacity: 1;
	}

	85% {
		transform: translateY(14px);
		opacity: 1;
	}

	100% {
		transform: translateY(0);
		opacity: 0;
	}
}
</style>
